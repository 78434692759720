<template>
  <div>
    <div class="border-bottom pb-3 mb-3">
      <FormGroup
        id="description"
        v-model="form.description"
        label="Description"
        :error="validationErrors.description"
        description="A friendly description of the automation"
      />
      <FormGroup
        id="category"
        v-model="form.category"
        label="Category"
        type="select"
        :options="categoryOptions"
        :error="validationErrors.category"
        description="What category does this automation apply to? For example, if data comes in via a company-wide email receiver then this would be 'Company'. If the automation fetches data for a single meter, then this would be 'Account'."
      />
      <FormGroup
        id="source"
        v-model="form.source"
        label="Source"
        type="select"
        :options="sourceOptions"
        :error="validationErrors.source"
        description="What source does this data come from?"
      />
      <FormGroup
        id="service"
        v-model="form.service"
        label="Service"
        type="select"
        :options="serviceOptions"
        :error="validationErrors.service"
        placeholder="Select a service..."
      />
      <FormGroup
        id="frequency"
        v-model="form.frequency"
        label="Frequency"
        type="select"
        :options="frequencyOptions"
        :error="validationErrors.frequency"
        description="How often this task is scheduled to run. 'Email Receiver' will run the task when an email is received."
      />
      <FormGroup
        v-if="form.category == 'account'"
        id="accountIds"
        v-model="form.accountIds"
        label="Accounts"
        type="select-array-list"
        :options="accountOptions"
        :error="validationErrors.accountId"
        placeholder="Select an account..."
        description="Accounts to apply this automation to"
      />
      <FormGroup
        v-if="form.category == 'asset'"
        id="assetId"
        v-model="form.assetId"
        label="Assets"
        type="select-array-list"
        :options="assetOptions"
        :error="validationErrors.assetId"
        placeholder="Select an asset..."
        description="Assets to apply this automation to"
      />
      <FormGroup
        id="active"
        v-model="form.active"
        label="Active"
        type="radio"
        :options="[
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import Form from '@/components/forms/Form';
import FormGroup from '@/components/FormGroup';

export default {
  name: 'ReportForm',
  components: {
    FormGroup
  },
  extends: Form,
  props: {
    formType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      sourceOptions: [
        { label: 'Email', value: 'email' },
        { label: 'FTP', value: 'ftp' },
        { label: 'Other', value: 'other' }
      ]
    };
  },
  computed: {
    ...mapGetters({
      validationErrors: 'report/validationErrors',
      accounts: 'account/accounts',
      assets: 'asset/assets',
      integrations: 'util/integrations'
    }),
    categoryOptions() {
      return [
        { label: 'Account', value: 'account' },
        { label: 'Asset', value: 'asset' },
        { label: 'Company', value: 'company' },
        ...(this.$auth.isAdmin ? [{ label: 'System', value: 'system' }] : [])
      ];
    },
    accountOptions() {
      const accounts = this.accounts.map(account => ({
        label: `${account.name} - ${account.meterPointNumber}`,
        value: account._id
      }));

      accounts.sort((a, b) => a.label.localeCompare(b.label));

      return accounts;
    },
    assetOptions() {
      const assets = this.assets
        .map(asset => ({
          label: asset.siteName,
          value: asset._id
        }))
        .filter(a => !this.form.assetIds.includes(a.value));

      assets.sort((a, b) => a.label.localeCompare(b.label));

      return assets;
    },
    serviceOptions() {
      return this.integrations.filter(option => option.category === this.form.category).map(({ service, name }) => ({ label: name, value: service }));
    },
    frequencyOptions() {
      return [
        { label: 'Never', value: 'never' },
        { label: 'Daily', value: 'daily' },
        { label: 'Hourly', value: 'hourly' },
        { label: 'Weekly', value: 'weekly' },
        { label: 'Monthly', value: 'monthly' }
      ];
    }
  },
  async mounted() {
    await Promise.all([this.getAccounts(), this.getAssets(), this.getAutomationTemplates(), this.listIntegrations()]);
  },
  methods: {
    ...mapActions({
      getAssets: 'asset/list',
      getAccounts: 'account/list',
      listIntegrations: 'util/listIntegrations'
    })
  }
};
</script>
